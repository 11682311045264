import React, {useRef, useState} from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

import {
  Title,
  Button,
  Section,
  Box,
  Text,
  Input,
  Select,
} from "../../components/Core";

import { device } from "../../utils";

import imgYellow from "../../assets/image/png/hero-shape-yellow.png";
import imgForm from "../../assets/image/png/hero-shape-combined.png";

const SectionStyled = styled(Section)``;

const ShapeYellow = styled(Box)`
  position: absolute;
  left: 0;
  transform: translateX(-63%) translateY(50%);
  bottom: 0;
  z-index: 1;
`;

const FormStyled = styled.form`
  position: relative;
  padding: 30px;
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.dark}`};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.light};
`;

const ShapeForm = styled(Box)`
  position: absolute;
  bottom: -58px;
  transform: scale(1.2);
  z-index: -1;
  @media ${device.md} {
    left: 13%;
  }
  @media ${device.lg} {
    bottom: 17px;
    transform: scale(1.5);
    z-index: -1;
    left: 0;
  }
  @media ${device.xl} {
    bottom: -58px;
    transform: scale(1.2);
    z-index: -1;
  }
`;


const Hero = () => {
  const recaptchaRef = useRef(null);
  const [buttonText, setButtonText] = useState("Send Message");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");

  const onSubmit = (e) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      if (buttonText === "Send Message") {
        setButtonText("Sending...");

        var data = {
          email: email,
          subject: 'document-scanning:' + subject,
          message: message,
          name: name
        };

        axios({
          url: `https://members.hotsnail.com.au/api/Support/ContactEmail?recaptcha=${recaptchaValue}&webpage=HotSnail`,
          //url: `https://localhost:44330/api/Support/ContactEmail?recaptcha=${recaptchaValue}&webpage=HotSnail`,
          headers: { 'content-type': 'application/json' },
          data: data,
          method: 'POST'
        })
          .then((response) => {
            setButtonText("Sent...");
            setEmail("");
            setSubject("");
            setMessage("");
            setName("");

            setTimeout(
              () => {
                setButtonText('Send Message');
              },
              [3000]
            );
          })
          .catch((response) => {
            console.log('Error emailing support', response);
            setButtonText('ERROR...');
            setTimeout(
              () => {
                setButtonText('Send Message');
              },
              [3000]
            );
          });
      }
    } else {
      setButtonText('Error with catpcha');
      setTimeout(
        () => {
          setButtonText('Send Message');
        },
        [3000]
      );
    }
  };




  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled bg="dark">
        <div className="pt-5"></div>
        <ShapeYellow className="d-none d-md-block">
          <img src={imgYellow} alt="" className="img-fluid" />
        </ShapeYellow>
        <Container
          className="position-relative"
          css={`
            z-index: 10;
          `}
        >
          <Row>
            <Col lg="4" xl="5" className="order-lg-2">
              <div className="position-relative w-100 h-100">
                <FormStyled>
                  <ShapeForm>
                    <img src={imgForm} alt="" className="img-fluid" />
                  </ShapeForm>
                  <Input
                    variant="animation"
                    type="email"
                    placeholder="Email address"
                    className="mb-4 active"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}

                  />
                  <Input
                    variant="animation"
                    type="text"
                    placeholder="Phone number"
                    className="mb-4 active"
                    required
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />

                  <textarea className="form-control" placeholder="Message"
                                      required
                                      value={message}
                                      onChange={(e) => setMessage(e.target.value)}
                  
                                        style={{marginBottom: "10px", minHeight: "100px"}}

                  ></textarea>


                  <ReCAPTCHA
                      sitekey="6LcJfXkUAAAAAEDyjhJ2wSgQQmX9-q260IXuHKv1"
                      ref={recaptchaRef}
                      style={{marginBottom: "10px"}}
                    />

                  <Button type="button" borderRadius="10px" width="100%" onClick={onSubmit}>
                    {buttonText}
                  </Button>
                </FormStyled>
              </div>
            </Col>
            <Col
              lg="8"
              xl="7"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                <Title variant="hero" color="light">
                  Professional document
                  <br className="d-none d-sm-block" /> scanning.
                </Title>
                <Text mb={4} color="lightShade">
                  Get your documents scanned by a pro team backed by a software development company. We'll upload your scans to the cloud, a custom app, or even a flash drive, starting at just $40 an hour.
                </Text>
              </Box>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
