import React from "react";
import Hero from "../sections/landing3/Hero";
import Feature from "../sections/landing2/Feature1";
import CTA from "../sections/landing2/CTA";
import PageWrapper from "../components/PageWrapper";
import Features from '../sections/landing4/Feature';
import '../assets/styles/global.css';
import Helmet from "react-helmet"
import '../assets/styles/documentScanning.css';

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>HotSnail — Mail Redirection, Mail Forwarding & Parcel Forwarding</title>
        <meta name="title" content="HotSnail — Mail Redirection, Mail Forwarding & Parcel Forwarding" />
        <meta name="description" content="Mail Redirection, Mail Scanning, Mail Forwarding & Australian Parcel forwarding from Australia." />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://hotsnail.com.au/" />
        <meta property="og:title" content="HotSnail — Mail Redirection, Mail Forwarding & Parcel Forwarding" />
        <meta property="og:description" content="Mail Redirection, Mail Scanning, Mail Forwarding & Australian Parcel forwarding from Australia." />
        <meta property="og:image" content="https://www.hotsnail.com.au/hotsnail/img/HotSnailLogo2.png"></meta>

      </Helmet>

      <PageWrapper footerDark>
        <Hero />
        <Feature />
        {/* <Content1 />
        <Content2 /> */}
        <CTA />
        <Features />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
