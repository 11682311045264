import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

import iconLayout from "../../assets/image/png/thumbs-shape.png";
import iconLayers from "../../assets/image/png/ScanEverything.png";
import iconResponsive from "../../assets/image/png/5star.png";

const SectionStyled = styled(Section)`
  padding-top: 47px;
  padding-bottom: 23px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  @media ${device.md} {
    padding-top: 87px;
    padding-bottom: 63px;
  }
`;

const FeatureCard = ({ iconImage, title, children, ...rest }) => (
  <Box {...rest}>
    <Box mb={[3, 3, 3, 4]} pb={2}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        <span style={{color:"white"}}>
        {title}
        </span>
      </Title>
      <Text variant="small" style={{color:"white"}}>{children}</Text>
    </Box>
  </Box>
);

const Feature = () => (
  <>
    {/* <!-- Feature section --> */}
    <SectionStyled bg="ash">
      <Container>
        <Row className="justify-content-center">
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard color="primary" title="Trusted Staff" iconImage={iconLayout}>
            All team members are vetted through comprehensive police background checks, ensuring a trustworthy and secure service environment. Our commitment to these rigorous standards underscores our dedication to maintaining the highest level of trust and safety with our clients
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard color="light" title="Professional Equipment" iconImage={iconLayers}>
            Our company provides state-of-the-art scanning equipment that surpasses the speed of standard office scanners, significantly enhancing efficiency. This advanced technology not only ensures rapid processing but also translates into substantial cost savings for our clients
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard color="light" title="Years of experience" iconImage={iconResponsive}>
            With 12 years of experience in the document scanning industry, our services are trusted by numerous companies across the Gold Coast and Brisbane. Our longstanding presence and reliability have made us a preferred partner for businesses in these regions.
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Feature;
